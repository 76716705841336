.react-tel-input .form-control {
    width: 100% !important;
    border-radius: 8px !important;
}

.react-tel-input .form-control:focus{
    border-color: #2196f3 !important;
    box-shadow: 0 0 0 1px #2196f3 !important;
}

.material-spinner {
    margin: auto;
    height: 50px;
    min-height: 50px;
    width: 50px;
    border-radius: 100px;
    border: 5px transparent solid;
    border-top: 5px #2196f3 solid;
    animation: material-spinner 4s infinite;
}

@keyframes material-spinner {
    0% {
        transform: rotate(0deg);
        border-top: 5px #2196f3 solid;
    }

    25% {
        transform: rotate(360deg);
        border-top: 5px #F44336 solid;
    }

    50% {
        transform: rotate(720deg);
        border-top: 5px #3F51B5 solid;
    }

    75% {
        transform: rotate(1080deg);
        border-top: 5px #4CAF50 solid;
    }

    100% {
        transform: rotate(1440deg);
        border-top: 5px #FFC107 solid;
    }
}
.apexcharts-menu-icon{
    display: none;
}